@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

body {
  font-family: "Mukta", sans-serif;
  margin: 0;
}

// Utility classes
.pointer {
  cursor: pointer;
}

// table tbody tr:hover {
//   background-color: #d1e3d6;
// }

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}

.ml-5px {
  margin-left: 5px;
}

.MuiTableRow-head th {
  font-weight: bold !important;
  font-size: 17px;
  //background-color: $pink;
  background-color: #E9F2EC;
  font-weight: 600;
  color: #000;
}

.cell-header-category {
  background-color: $secondary;
}

.MuiTypography-h6 {
  font-size: 18px !important;
}

.MuiTableCell-sizeSmall {
  padding: 6px 6px 6px 16px !important;
}

.MuiCardHeader-root {
  border-bottom: 1px solid #ccc;
}

.MuiList-padding {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}

.card-add {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px !important;
}

.card-filters {
  padding-top: 10px;
  padding-bottom: 10px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center;
}

.pt-5 {
  padding-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.text-primary {
  color: $primary;
}

.card-login {
  background-color: $primary;
  color: #fff;
}

.link-primary {
  color: $primary;
  font-weight: bold;
}

.link-primary:hover {
  color: $secondary;
  font-weight: bold;
}

.link-login {
  color: $primary;
  font-weight: bold;
}

.link-login:hover {
  color: $primary;
  text-decoration: underline;
  font-weight: bold;
}

.descriptionProduct {
  font-size: 12px;
}

.title-card-big {
  font-size: 20px;
  font-weight: 500;
}

.table-attributes > thead > tr > th {
  background-color: $primary;
  padding: 10px;
  color: #fff;
  margin: 5px;
  text-align: left;
}

.table-attributes tr:hover {
  background-color: $secondary;
}

.table-attributes > tbody > tr > td {
  background-color: $secondary !important;
  padding: 10px;
  font-weight: 300;
  margin: 5px;
  text-align: left;
}

.table-attributes {
  border-spacing: 10px;
}

.mini-title-bold {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

.list-vbh {
  padding: 0 10px 0 15px;
  font-size: 12px;
}

.icons-list {
  display: flex;
}

.icons-list > svg {
  height: 18px;
  opacity: 0.2;
  margin-right: 10px;
  fill: $primary;
}

.icons-list > svg:hover {
  opacity: 1;
  fill: $primary;
}

.table-dati-tecnici > tr {
  margin-bottom: 10px;
}

.bg-dati-tecnici {
  background-color: #f5f5f5;
}

.bg-varianti {
  background-color: $primary;
  color: white !important;
  font-weight: 700 !important;
}

.bg-varianti-scuro {
  background-color: #f5f5f5;
  color: black;
  font-weight: 700 !important;
}

.bg-varianti-chiaro {
  background-color: #d1e3d6;
  color: black;
  font-weight: 700 !important;
}

.card-vantaggi {
  height: 300px;
  overflow: scroll;
}

.card-variants {
  height: 100px;
  overflow: scroll;
}

.background-red > td {
  font-weight: 700;
}

.text-red {
  color: red !important;
}

.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.card-header-no-border {
  border-bottom: none;
}

.card-header-no-border > div > span{
  font-size: 20px;
  font-weight: 600;
}

.card-no-padding {
  padding: 0 !important;
}
